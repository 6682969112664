<script setup>
const props = defineProps({
  size: {
    type: String,
    default: 'small',
    required: false,
  },
  density: {
    type: String,
    default: 'default',
    required: false,
  },
  status: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
    default: 'gray',
  },
});
const statusText = computed(() => normalise(props.status || ''));
const statusColor = computed(() => {
  if (props.color === 'blue') {
    return { textColor: '#1C7599', background: '#EDFAFF' };
  }
  if (props.color === 'red') {
    return { textColor: '#E40000', background: '#FFD9D9' };
  }
  if (props.color === 'green') {
    return { textColor: '#0A9663', background: '#B6FFE4' };
  }
  return { textColor: '#5F747D', background: '#F0F0F0' };
});
</script>

<template>
  <v-chip
    :style="`color: ${statusColor.textColor} !important;
     background: ${statusColor.background} !important;font-weight: bold;`"
    :density="density"
    :class="`pt-custom-status-chip` "
    :size="size"
    variant="tonal"
  >
    <span>
      • &nbsp;{{ statusText }}
    </span>
  </v-chip>
</template>

<style lang="scss">
.pt-custom-status-chip{

}
</style>
