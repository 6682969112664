import { useUserStore } from '~/store/user';

export const useAirVisibility = defineStore('airVisibility', {
  state: (): any => ({
    items: [],
    total: 0,
    isLoading: true,
  }),
  actions: {
    async getAirBookmarks() {
      const runtimeConfig = useRuntimeConfig();
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      const route = useRoute();
      try {
        this.isLoading = true;
        let params = '?pagination_limit=20';
        if (route.query.q) {
          params += `&airway_bill=${route.query.q}`;
        }
        if (route.query.page) {
          params += `&page=${route.query.page}`;
        }
        const res = await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}/eta/tracking/air-bookmarks${params}`);
        this.items = res.results.map((bookmark: any) => formatAirVisibilityBookmark(bookmark));
        this.total = res.total_count;
      }
      catch (error) {
        // TODO: Handle error
      }
      finally {
        this.isLoading = false;
      }
    },
    async addMAWBBookmarks(payload: any) {
      const runtimeConfig = useRuntimeConfig();
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      try {
        return await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}/eta/tracking/air-bookmarks`, {
          method: 'POST',
          body: JSON.stringify(payload),
        });
      }
      catch (error) {
        console.error(error);
      }
      finally {
        this.isLoading = false;
      }
    },
  },
});
